export default () => {
  const $textarea = $(".js-text-area");

  $textarea.on("input", function() {
    const { length } = this.value;
    const $textLimitTip = $(this.previousSibling);

    if (length > 250) {
      $textLimitTip.html(`已经超过${length}/250个字符`).addClass("t-show");
    } else {
      $textLimitTip.removeClass("t-show");
    }
  });
};
