/* eslint-disable no-plusplus */
/* eslint-disable no-else-return */
import form from "./form";

const getFileIcon = name => {
  const fileType = name.match(/(.+?)(\.[^.]*$|$)/)[2];

  if (fileType === ".pdf") {
    return "icon-pdf t-pdf";
  } else if (
    fileType === ".png" ||
    fileType === ".jpg" ||
    fileType === ".jpeg"
  ) {
    return "icon-jpg t-image";
  } else if (fileType === ".ppt") {
    return "icon-ppt t-ppt";
  } else if (/^\.doc/.test(fileType)) {
    return "icon-file_word t-word";
  } else {
    return "icon-wenjian t-default";
  }
};

export default () => {
  form("t-middle");
  $(".js-form-file").on("change", function() {
    const { files } = this;
    const fileLength = files.length;
    let html = "";
    for (let i = 0; i < fileLength; i++) {
      const { name } = files[i];
      html += `<div class="form--filename"><i class="iconfont form--fileicon ${getFileIcon(
        name
      )}"></i>${name}</div>`;
    }

    this.nextSibling.innerHTML = html;
  });
};
