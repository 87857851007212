import { removeAlert } from "./tools";

export default () => {
  const $alertContainer = $(".js-alert-container");
  $alertContainer.on("click", ".js-alert-close", function() {
    this.parentElement.remove();
  });
  const $alertItems = $(".u-alert__item");
  if ($alertItems.length > 0) {
    removeAlert($alertItems);
  }
};
