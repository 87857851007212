import { xhrAddTip } from "./tools";

export default () => {
  $(document).on("ajax:error", event => {
    xhrAddTip("error", event.detail[0].errors[0]);
  });

  // .on('ajax:success', (event) => {
  //   addTipToBody('success', event);
  // })
};
