import Swiper from "swiper";

export default () => {
  let [leftIndex, rightIndex] = [0, 0];
  new Swiper(".swiper-container", {
    slidesPerView: "auto",
    spaceBetween: 14,
    centeredSlides: true,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
  });

  $(".apply").on("click", () => {
    $(".hint-modal").css("display", "block");
    $(".hint-backdrop").css("display", "block");
    trackContactSyncedEvent();
  });

  $(".add-solution").on("click", () => {
    $(".add-solution-modal").css("display", "block");
    $(".hint-backdrop").css("display", "block");
  });

  $(".close").on("click", () => {
    $(".hint-modal").css("display", "none");
    $(".hint-backdrop").css("display", "none");
    $(".add-solution-modal").css("display", "none");
  });

  $(".hint-backdrop").on("click", () => {
    $(".hint-modal").css("display", "none");
    $(".hint-backdrop").css("display", "none");
    $(".add-solution-modal").css("display", "none");
  });

  $("#pre-btn").on("click", () => {
    leftIndex += 1;
    $("#topics-card").scrollLeft(-408 * leftIndex);
  });

  $("#next-btn").on("click", () => {
    rightIndex += 1;
    $("#topics-card").scrollLeft(408 * rightIndex);
  });

  $(".js-open-modal").each((i, dom) => {
    $(dom)[0].addEventListener("click", () => {
      trackReviewMoreSolutionEvent($(dom).data("scenario"));
    });
  });

  $(".signup").on("click", () => {
    trackSignupEvent();
  });

  // $('.apply').each((i, dom) => {
  //   console.log(dom);
  //   $(dom)[0].addEventListener('click', (e) => {
  //     console.log('')
  //     trackContactSyncedEvent()
  //   })
  // })

  var eventTrack = (...args) => {
    if (!window.gtag) {
      return;
    }
    // eslint-disable-next-line no-undef
    gtag(...args);
  };

  var trackReviewMoreSolutionEvent = name => {
    eventTrack("event", "review_more_solution", {
      event_category: name,
    });
  };

  var trackContactSyncedEvent = () => {
    eventTrack("event", "contact_synced_in_solution", {});
  };

  var trackSignupEvent = () => {
    eventTrack("event", "signup_in_institution", {});
  };
};
