import "tooltipster";
import { getTargetInfo } from "@shared/scripts/features/api";
import { graphTypes } from "@shared/scripts/utils/site_text_constant";

const getHtml = (type, path, node) => {
  const {
    institutions,
    avatar_url,
    logo_url,
    name,
    summary,
    technologies,
    summary_urls,
    website,
    completeness,
  } = node;
  let mechas = "";
  let fields = "";
  let origins = "";
  let websiteHtml = "";
  let image = "";
  let footer = "";

  // institutions
  if (type === "experts" && institutions.length > 0) {
    mechas = `
      <div class="graph__about">
        <div class="graph__title graph__sub">所属机构</div>
        <div class="graph__mechas">
    `;
    institutions.forEach(item => {
      mechas += `<a class="graph__mecha" href="javascript:;">${item.name}</a>`;
    });
    mechas += "</div></div>";
  }

  // technologies
  if (type !== "technologies" && technologies.length > 0) {
    fields = `
      <div class="graph__about">
        <div class="graph__title graph__sub">相关技术</div>
        <div class="graph__fields">
    `;
    technologies.forEach(item => {
      fields += `<a class="graph__field" href="javascript:;">${item.name}</a>`;
    });

    fields += "</div></div>";
  }

  // origin
  if (
    type !== "institutions" &&
    summary_urls !== null &&
    summary_urls.length > 0
  ) {
    origins = `
      <div class="graph__title graph__origins">
        <i class="iconfont icon-link2 u-mr-10"></i>来源：
    `;
    summary_urls.forEach(item => {
      origins += `<a class="graph__origin" href="${item.link}" target="_blank">${item.name}</a>`;
    });
    origins += "</div>";
  }

  // url link
  if (type === "institutions" && website !== null && website !== "") {
    websiteHtml = `<a class="graph__title graph__link u-mr-10" href="${website}" target="_blank"><i class="iconfont icon-link2 u-mr-10"></i>${website}</a>`;
  }

  // image
  if (type === "institutions" && logo_url !== null) {
    image += `<div class="graph__logo" style="background-image: url(${logo_url})"></div>`;
  }
  if (type === "experts" && avatar_url !== null) {
    image += `<div class="graph__avatar" style="background-image: url(${avatar_url})"></div>`;
  }

  if (completeness !== "level1") {
    footer = `<a class="graph__footer" href=${path} target="_blank">查看详情</a>`;
  }

  return `
    <div class="graph__${type}">
      <div class="graph__title">MI 信息数据平台 · ${graphTypes[type]}</div>
      <div class="graphs__box">
        ${image}
        <div class="graph__header">
          <div class="u-main-title graph__keyword">${name}</div>
        </div>
        <p class="graph__content">${summary}</p>
        ${websiteHtml}
        ${origins}
      </div>
      ${fields}
      ${mechas}
    </div>
    ${footer}
  `;
};

const options = {
  theme: "tooltipster-shadow",
  interactive: true,
  contentAsHTML: true,
  updateAnimation: null,
  content: "",
  functionBefore: (instance, helper) => {
    const $origin = $(helper.origin);
    let type = $origin.data("type");
    const id = $origin.data("id");

    if ($origin.data("loaded") === true) return;
    const path = `/graph/${type}/${id}`;
    getTargetInfo(path).done(res => {
      if (
        type === "tech_methods" ||
        type === "tech_tasks" ||
        type === "concepts"
      ) {
        type = "technologies";
      }
      instance.content(getHtml(type, path, res.data));
    });
  },
};

export default options;
