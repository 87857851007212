import { editorInit } from "@shared/scripts/utils/tool";
import textares from "./form_textarea";
import { useSelect2WithAjax } from "./tools";

export default (otherClass = "") => {
  textares();
  editorInit(".js-editor");

  const $infoIcon = $(".js-form-icon");
  const toggleClassName = otherClass === "" ? "t-show" : `t-show ${otherClass}`;

  $infoIcon
    .mouseover(function() {
      $(this)
        .parent()
        .prev()
        .addClass(toggleClassName);
    })
    .mouseout(function() {
      $(this)
        .parent()
        .prev()
        .removeClass(toggleClassName);
    });

  useSelect2WithAjax();
};
