import form from "./form";

export default () => {
  const removeBtn =
    '<button class="u-btn form--remove js-remove-btn" type="button" data-type="remove">-</button>';
  const addBtn =
    '<button class="u-btn form--add js-add-btn" data-type="add" type="button">+</button>';

  const htmlAction = btnHtml => {
    return `
      <div class="form--inside js-form-inside"><input type="text" name="jigou_business_case[website][]" class="form--input" placeholder="输入格式为https://xxx.com">${btnHtml}</div>
    `;
  };

  form("t-large");

  const clientSelect = document.getElementById("jigou_business_case_clients");
  const inWebsitetList = $("#js-website-list");

  $('input[name="jigou_business_case[client_self]"').on("change", function() {
    if (this.value === "false") {
      clientSelect.removeAttribute("disabled");
    } else {
      clientSelect.setAttribute("disabled", "true");
    }
  });

  inWebsitetList.on("click", ".js-add-btn, .js-remove-btn", event => {
    const $currentTarget = $(event.currentTarget);
    const actionType = $currentTarget.data("type");
    const $currentInside = $currentTarget.closest(".js-form-inside");

    if (actionType === "remove") {
      $currentInside.remove();
    }

    if (actionType === "add") {
      $currentTarget.remove();
      $currentInside.append(removeBtn);
      inWebsitetList.append(htmlAction(addBtn));
    }
  });
};
