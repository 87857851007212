import Swiper from "swiper";

export default () => {
  new Swiper(".swiper-container", {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
      delay: 30000,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  });

  $(".hovered").hover(
    e => {
      $(e.currentTarget)
        .find(".common")
        .css("display", "none");
      $(e.currentTarget)
        .find(".hide")
        .addClass("show");
    },
    e => {
      $(e.currentTarget)
        .find(".common")
        .css("display", "block");
      $(e.currentTarget)
        .find(".hide")
        .removeClass("show");
    }
  );

  let initSmoothScrolling = (container, animation) => {
    let sliderWidth = 0;
    let animationWidth = 0;

    $(".jigou-service__marquee__item", container).each((i, dom) => {
      animationWidth += $(dom).outerWidth(true);
    });

    let slidesVisible =
      $(container).width() /
      $(".jigou-service__marquee__item:first-of-type", container).outerWidth(
        true
      );
    slidesVisible = Math.ceil(slidesVisible);

    let slidesNumber = $(".jigou-service__marquee__item", container).length;
    var speed = slidesNumber * 6;

    $(".jigou-service__marquee__item", container)
      .slice(0, slidesVisible)
      .clone()
      .appendTo($(">div", container));

    $(".jigou-service__marquee__item", container).each((i, dom) => {
      sliderWidth += $(dom).outerWidth(true);
    });

    animationWidth += 50;

    $(">div", container).css({ width: sliderWidth });

    $(
      "<style type='text/css'>@keyframes " +
        animation +
        " { 0% { margin-left: 0px; } 100% { margin-left: -" +
        animationWidth +
        "px; } } " +
        ".jigou-service__marquee__item:first-of-type { -webkit-animation: " +
        animation +
        " " +
        speed +
        "s linear infinite; -moz-animation: " +
        animation +
        " " +
        speed +
        "s linear infinite; -ms-animation: " +
        animation +
        " " +
        speed +
        "s linear infinite; -o-animation: " +
        animation +
        " " +
        speed +
        "s linear infinite; animation: " +
        animation +
        " " +
        speed +
        "s linear infinite; }</style>"
    ).appendTo("head");
  };

  initSmoothScrolling(".jigou-service__marquee", "smoothscroll");
};
