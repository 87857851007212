import "./styles/app.scss";
// import 'select2';
import "select2/dist/js/select2.full.js";
import "select2/dist/js/i18n/zh-CN";
import turbolinks from "turbolinks";
import RailsUJS from "rails-ujs";
import "froala-editor/js/froala_editor.pkgd.min";
import "froala-editor/js/languages/zh_cn";
import "bootstrap/js/modal";
import "bootstrap/js/tooltip";
import "bootstrap/js/popover";

import myAlert from "./components/alert";
import setting from "./components/global_setting";
import notification from "./components/notification";
import managementForm from "./components/management_form";
import businessCaseForm from "./components/business_case_form";
import ajaxFeedback from "./components/ajax_feedback";
import formTextares from "./components/form_textarea";
import service from "./components/service";
import accountForm from "./components/account_form";
import signup from "./components/signup";
import signin from "./components/signin";
import welcome from "./components/welcome";
import solution_form from "./components/solution_form";
import platform from "./components/platform";
import init from "./components/init.js";
import * as Vibrant from "node-vibrant";
import banner from "jigou/banner";
import collection from "./components/collection.js";
import modal from "./components/modal";

const runPage = (condition, cb) => {
  if (condition) cb();
};

export default () => {
  RailsUJS.start();
  turbolinks.start();
  ajaxFeedback();

  const openDialog = ele => {
    ele.addClass("show");
    $(".code-modal__mask").addClass("show");
  };
  const closeDialog = () => {
    $(".code-modal__auto-byte").removeClass("show");
    $(".code-modal__science-ai").removeClass("show");
    $(".code-modal__mask").removeClass("show");
  };

  $(".auto-byte").on("click", () => {
    openDialog($(".code-modal__auto-byte"));
    document.body.style.overflow = "hidden";
  });

  $(".science-ai").on("click", () => {
    openDialog($(".code-modal__science-ai"));
    document.body.style.overflow = "hidden";
  });

  $(".code-modal__mask").on("click", () => {
    closeDialog();
    document.body.style.overflow = "";
  });

  $(".code-modal__close").on("click", () => {
    closeDialog();
    document.body.style.overflow = "";
  });

  // startListenModalCloseEvent();
  // startListenSettingModalHeader();

  document.addEventListener("turbolinks:before-cache", () => {
    $(".js-select2, .js-aliase-select2").remove();
  });

  document.addEventListener("turbolinks:load", () => {
    const $body = $("body");
    const $head = $("head");
    const pageId = $body.attr("id");
    const controllerName = $body.data("controller");

    const script = document.createElement("script");
    script.text =
      "(function(m, ei, q, i, a, j, s) {m[i] = function() {(m[i].a = m[i].a || []).push(arguments)};j = ei.createElement(q),    s = ei.getElementsByTagName(q)[0];j.async = true;j.charset = 'UTF-8';j.src = 'https://static.meiqia.com/dist/meiqia.js?_=t';s.parentNode.insertBefore(j, s);})(window, document, 'script', '_MEIQIA');_MEIQIA('entId', 193935);";
    $head.append(script);

    myAlert();
    setting();
    runPage(/^solutions/.test(controllerName), solution_form);
    runPage(/^service_/.test(controllerName), service);
    runPage(/^business_cases/.test(controllerName), businessCaseForm);
    runPage(pageId === "managements-edit", managementForm);
    runPage(pageId === "notifications-index", notification);
    runPage(pageId === "orders-new", formTextares);
    runPage(pageId === "accounts-edit", accountForm);
    runPage(pageId === "accounts-new", signup);
    runPage(pageId === "sessions-new", signin);
    runPage(pageId === "welcome-index", welcome);
    runPage(pageId === "managements-platform", platform);
    runPage(pageId === "home-index", init);
    runPage(pageId === "collections-index", collection);
    runPage(pageId === "collections-show", collection);
    runPage(pageId === "collections-index", modal);

    // $('[data-toggle="popover"]').popover({html: true});

    $(".pop")
      .popover({ trigger: "manual", html: true, animation: false })
      .on("mouseenter", function() {
        var _this = this;
        $(this).popover("show");
        $(".popover").on("mouseleave", function() {
          $(_this).popover("hide");
        });
      })
      .on("mouseleave", function() {
        var _this = this;
        setTimeout(function() {
          if (!$(".popover:hover").length) {
            $(_this).popover("hide");
          }
        }, 300);
      });

    $("body").on("contextmenu", "a[data-remote='true']", function(e) {
      e.preventDefault();
    });

    $("body").on("click", "span[data-remote='true']", function(e) {
      const href = $(e.target).data("href");
      $.ajax(href);
    });
  });

  Vibrant.from(banner).getPalette((err, palette) => {
    const color = palette.DarkVibrant.rgb.join(",");
    $(".section-header__banner__content").css({
      "background-color": "rgb(" + color + ")",
    });
  });

  const accountBanner = document.querySelectorAll(
    ".account-header__banner img"
  )[0];

  accountBanner &&
    Vibrant.from(accountBanner.src)
      .getPalette()
      .then(palette => {
        const res = Object.values(palette).reduce(
          (pre, current) =>
            (pre = current.population > pre.population ? current : pre),
          {
            population: 0,
          }
        );
        const color = res.rgb.join(",");
        $(".account__header").css({
          "background-color": "rgb(" + color + ")",
        });
      });
};

// const startListenModalCloseEvent = () => {
//   $("#close-modal-btn").on("click", () => {
//     $("#form-modal").modal("toggle");
//   });
// };
