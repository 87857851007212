export default () => {
  // modal
  const $switchModalBtn = $(".js-switch-modal");
  const $modal = $("#js-account-modal");
  const $modalContent = $("#js-modal-content");

  $switchModalBtn.on("click", () => {
    $modal.toggleClass("is-show");
  });

  $modal.on("click", () => {
    $modal.removeClass("is-show");
  });

  $modalContent.on("click", event => {
    event.stopPropagation();
  });
};
