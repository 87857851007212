/* eslint-disable no-plusplus */
export const removeAlert = $target => {
  let animationFrameId = null;
  let count = 0;
  const addClassCount = 3;
  const removeClassCount = addClassCount + 4 * 60;
  const removeCount = removeClassCount + 12;

  const step = () => {
    count++;
    if (count === addClassCount) {
      $target.addClass("is-in");
    }

    if (count === removeClassCount) {
      $target.removeClass("is-in");
    }

    if (count === removeCount) {
      $target.remove();
      cancelAnimationFrame(animationFrameId);
      return;
    }
    requestAnimationFrame(step);
  };

  animationFrameId = requestAnimationFrame(step);
};

export const addTipToBody = (type = "error", message) => {
  const $alert = $(
    `<div class='u-alert__item'><i class='iconfont icon-${type} u-mr-10'></i>${message.replace(
      /"/g,
      ""
    )}</div>`
  );
  $(".js-alert-container").append($alert);
  removeAlert($alert);
};

export const xhrAddTip = (type = "error", responseText) => {
  let message = responseText.error;
  if (!message && type === "success") return;

  if (!message) {
    message = JSON.stringify(responseText);
  }

  addTipToBody(type, message);
};

export const useSelect2WithAjax = (
  selector = ".js-select2",
  width = "468px"
) => {
  $(selector).each((_, select) => {
    const $item = $(select);
    const placeholder = $item.attr("placeholder");
    const type = $item.data("type");

    $item.select2({
      allowClear: true,
      language: "zh-CN",
      placeholder,
      width,
      ajax: {
        url: `/api/jigou/search?type=${type}`,
        dataType: "json",
        type: "GET",
        data: params => {
          return {
            keyword: params.term,
          };
        },
        processResults: (data, params) => {
          let results = [];
          let isIncludeParams = false;
          if (data.length > 0) {
            results = data.map(item => {
              const itemName = item.name;
              if (itemName === params.term) {
                isIncludeParams = true;
              }
              return {
                text: itemName,
                id: itemName,
              };
            });
          }

          if (!isIncludeParams) {
            const keyword = params.term;
            results.unshift({
              text: keyword,
              id: keyword,
            });
          }
          return {
            results,
          };
        },
      },
    });
  });
};

export const useSelect2WithoutAjax = (
  selector,
  width = "468px",
  isHasCustomTag = true
) => {
  $(selector).select2({
    allowClear: true,
    language: "zh-CN",
    placeholder: "输入并选取对应文字；可输入多个",
    tags: isHasCustomTag,
    width,
  });
};
