import { addTipToBody, xhrAddTip } from "./tools";

const getFormFiled = $form => {
  const $inputs = $form.find("input");
  const inputObj = {};

  $inputs.each((_, item) => {
    inputObj[item.name] = item.value;
  });

  return inputObj;
};

export default () => {
  const $accountForm = $("#form");
  const $accountFormBtn = $(".js-form-submit");
  const $passwordForm = $("#form-password");
  const $passwordBtn = $("#js-password-submit");
  const $password = $("#js-new-password");
  const $passwordInput = $(".js-password-input");

  const addEventListenerToForm = ($form, $formBtn) => {
    $form.on("input", "input", () => {
      $formBtn.removeAttr("disabled");
      $form.off("input");
    });
  };

  const addSubmitEvent = ($form, $formBtn, condition) => {
    $formBtn.on("click", function() {
      const $this = $(this);

      if ($this.hasClass("is-disabled")) return;
      if (condition && !condition()) return;
      const url = $form.attr("action");
      const method = $form.attr("method");

      $.ajax({
        url,
        method,
        data: getFormFiled($form),
      })
        .done(res => {
          addTipToBody("success", res.success);
          $this.attr("disabled", true);
          addEventListenerToForm($form, $formBtn);
        })
        .fail(event => {
          xhrAddTip("error", event.responseJSON.errors[0]);
        });
    });
  };

  addEventListenerToForm($accountForm, $accountFormBtn);
  addEventListenerToForm($passwordForm, $passwordBtn);

  addSubmitEvent($accountForm, $accountFormBtn);
  addSubmitEvent($passwordForm, $passwordBtn, () => {
    let flag = true;
    $passwordInput.each((_, item) => {
      if (flag && item.value === "") {
        flag = false;
      }
    });

    return flag;
  });

  $passwordInput.on("input", function() {
    $(this).toggleClass("is-error", !/^\S{6,20}$/.test(this.value));
  });

  $("#js-confirm-password").on("change", function() {
    $(this).toggleClass("is-error", $password.val() !== this.value);
  });
};
