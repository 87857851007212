/* eslint-disable no-new */
import Swiper from "swiper";

export default () => {
  new Swiper("#js-welcome-swiper", {
    direction: "vertical",
    loop: true,
    autoplay: {
      delay: 5000,
    },
  });
};
