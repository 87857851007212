/* eslint-disable no-new */
import Cleave from "cleave.js";
import form from "./form";
import { useSelect2WithAjax, useSelect2WithoutAjax } from "./tools";

const addBtn =
  '<button class="u-btn form--add js-add-btn" data-type="add" type="button"> +</button>';
const removeBtn =
  '<button class="u-btn form--remove js-remove-btn" type="button" data-type="remove">-</button>';

const createDateFormat = () => {
  $(".js-time-format")
    .toArray()
    .forEach(field => {
      new Cleave(field, {
        date: true,
        delimter: "/",
        datePattern: ["Y", "m"],
      });
    });
};

const getInvestmentItemHtml = btnHtml => {
  return `
<div class="form--inline t-flex t-sub js-form-inside">
<div class="js-form-data">
<input class="form--input t-investor" placeholder="例：在机器之心2020年榜单中" type="text" name="jigou_institution[[investors_obj][][investee]]">
<input class="form--input t-investor" placeholder="例：获得了最强人工智能TOP10" type="text" name="jigou_institution[[investors_obj][][date]]">
${btnHtml}
</div>
</div>
  `.replace(/\n|\r/g, "");
};

const appendAdditonalInfo = ({
  $inline,
  htmlAction,
  indexSelector,
  indexText,
}) => {
  $inline.on("click", ".js-add-btn, .js-remove-btn", event => {
    const $currentTarget = $(event.currentTarget);
    const actionType = $currentTarget.data("type");

    if (actionType === "remove") {
      $currentTarget.closest(".js-form-inside").remove();
      $(indexSelector).each((index, item) => {
        $(item).html(`${indexText}${index + 1}：`);
      });
    }

    if (actionType === "add") {
      const $currentData = $currentTarget.closest(".js-form-data");
      $currentTarget.remove();
      $currentData.append(removeBtn);
      $inline.append(htmlAction(addBtn, $inline.children().length + 1));
    }
  });
};

const fixedProgress = () => {
  $(window).on("scroll", () => {
    const contentTop = $(".form-section")[0].getBoundingClientRect().top;
    $(".progress-line").css({
      position: contentTop <= 0 ? "fixed" : "absolute",
    });
  });
};

const showFilledProgress = () => {
  const formFieldCount = $(".form-field").length;
  const formFieldObj = {};
  const formFieldArr = [];

  $(".form-field").each((i, dom) => {
    formFieldObj[dom.id] = false;
    if ($(dom)[0].type === "file") {
      formFieldObj[dom.id] =
        $(dom).data("value") !== "" && $(dom).data("value") !== undefined;
    }
    if ($(dom)[0].value) {
      formFieldObj[dom.id] = true;
    }
    $(dom).on("input", e => {
      if (dom.id === "business_area") {
        formFieldObj[dom.id] = $(`#${dom.id} > input[type='checkbox']`).is(
          ":checked"
        );
      } else {
        formFieldObj[dom.id] = e.target.value !== "";
      }
      formFieldArr.length = 0;
      Object.keys(formFieldObj).forEach(key => {
        if (formFieldObj[key]) {
          formFieldArr.push(key);
        }
      });
      $("#progress").css({
        width: `${(formFieldArr.length / formFieldCount).toFixed(2) * 100}%`,
      });
      $("#jigou_institution_completeness")[0].value =
        (formFieldArr.length / formFieldCount).toFixed(2) * 100;
      $(".progress-line--value")[0].innerText = `已完成：${(
        (formFieldArr.length / formFieldCount) *
        100
      ).toFixed()}%`;
      $(".progress-line--value").css({
        left: `calc(${(formFieldArr.length / formFieldCount).toFixed(2) *
          100}% - 60px`,
      });
    });

    $(dom).on("froalaEditor.contentChanged", () => {
      formFieldObj[dom.id] = $(".fr-counter")[0].innerHTML - 0 > 0;
      formFieldArr.length = 0;
      Object.keys(formFieldObj).forEach(key => {
        if (formFieldObj[key]) {
          formFieldArr.push(key);
        }
      });
      $("#progress").css({
        width: `${(formFieldArr.length / formFieldCount).toFixed(2) * 100}%`,
      });
      $("#jigou_institution_completeness")[0].value =
        (formFieldArr.length / formFieldCount).toFixed(2) * 100;
      $(".progress-line--value")[0].innerText = `已完成：${(
        (formFieldArr.length / formFieldCount) *
        100
      ).toFixed()}%`;
      $(".progress-line--value").css({
        left: `calc(${(formFieldArr.length / formFieldCount).toFixed(2) *
          100}% - 60px`,
      });
    });
  });
  Object.keys(formFieldObj).forEach(key => {
    if (formFieldObj[key]) {
      formFieldArr.push(key);
    }
  });
  $("#progress").css({
    width: `${(formFieldArr.length / formFieldCount).toFixed(2) * 100}%`,
  });
  $("#jigou_institution_completeness")[0].value =
    (formFieldArr.length / formFieldCount).toFixed(2) * 100;
  $(".progress-line--value")[0].innerText = `已完成：${(
    (formFieldArr.length / formFieldCount) *
    100
  ).toFixed()}%`;
  $(".progress-line--value").css({
    left: `calc(${(formFieldArr.length / formFieldCount).toFixed(2) *
      100}% - 60px`,
  });
};

export default () => {
  form("t-institution");
  useSelect2WithoutAjax(".js-aliase-select2");
  useSelect2WithAjax(".js-new-select2", "300px");
  useSelect2WithoutAjax(".js-category-select2");

  createDateFormat();

  showFilledProgress();
  fixedProgress();

  const $additionalInfoInline = $("#js-additional-info-inline");
  appendAdditonalInfo({
    $inline: $additionalInfoInline,
    indexSelector: ".js-additional-info-index",
    htmlAction: getInvestmentItemHtml,
    indexText: "补充信息",
  });
};
