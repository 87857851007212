import Salert from "react-s-alert";
import {
  bottomBarActionInModal,
  shareAction,
  scrollBarWith,
  articleInfoNodesAction,
} from "@shared/scripts/utils/tool";
import options from "./card_options";

const remoteModal = () => {
  const $modal = $("#js-modal");
  const $container = $("#js-modal-container");

  const closeModal = () => {
    $container.html("");
    window.history.back();
  };

  const openModal = () => {
    $("body")
      .css("padding-right", `${scrollBarWith()}px`)
      .addClass("open-modal-layer");
    $modal.removeClass("is-hidden");
  };

  $(window).on("popstate", () => {
    $("body")
      .removeClass("open-modal-layer")
      .attr("style", "");
    $modal.addClass("is-hidden");
  });

  $modal.on("click", e => {
    const className = $(e.target).attr("class");
    if (/(js-daily-modal)|(icon-iconguanbi)|(icon-open)/.test(className)) {
      closeModal();
    }
  });

  $("#js-has-modal")
    .on("ajax:before", ".js-open-modal", e => {
      const url = e.currentTarget.href;
      window.history.pushState("", "", `${url}`);
      openModal();

      $container.html(`
        <div class="modal-layer__loading">
          <div class="u-loading__container"><div class="u-loading__inner"><div class="u-loading__logo"></div></div></div>
        </div>`);
    })
    .on("ajax:complete", ".js-open-modal", e => {
      const url = e.currentTarget.href;
      shareAction(url);
      // $('.js-qrcode').qrcode({ text: url, width: 120, height: 120 });

      // comment
      // comment();

      if (url.indexOf("articles") !== -1) {
        bottomBarActionInModal(".js-article-modal");

        // card
        // const $content = $('#js-article-content');
        // let timeout = null;
        $("#js-modal-container mark").tooltipster(options);
        articleInfoNodesAction();
        // $('.js-article-modal').on('scroll', () => {
        //   $content.removeClass('is-show-keyword');
        //   clearTimeout(timeout);
        //   timeout = setTimeout(() => {
        //     $content.addClass('is-show-keyword');
        //   }, 5000);
        // });
      }
    })
    .on("ajax:error", ".js-open-modal", (e, xhr) => {
      closeModal();
      Salert.error(xhr.responseText);
    });
};

export default remoteModal;
