export default () => {
  const $item = $(".js-notification-item");
  const $pageJump = $("#js-pagination-jump");

  $item.on("click", ".js-check-detail", event => {
    $(event.delegateTarget)
      .toggleClass("t-show")
      .removeClass("is-unread");
  });

  $pageJump.on("keydown", function(event) {
    if (event.which === 13) {
      const val = this.value;
      if (val && /^(\d)*$/.test(val)) {
        window.location = `notifications?page=${val}`;
      }
    }
  });
};
